import React, { useState } from "react";
import { Helmet } from "react-helmet"; // Import Helmet for managing meta tags
import styles from "./navbar.module.scss";
import logo from "../../Logo1.svg";
import logo2 from "../../Logo2.svg";

const NavBar = () => {
  // STATE TO HANDLE LOGO HOVER EFFECT
  const [isHovered, setIsHovered] = useState(false);
  // STATE TO CONTROL MENU OPEN/CLOSE
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  // STATE TO MANAGE CLOSING ANIMATION
  const [isClosing, setIsClosing] = useState(false);

  // FUNCTION TO TOGGLE THE MENU OPEN/CLOSE
  const toggleMenu = () => {
    if (isMenuOpen) {
      setIsClosing(true); // STARTS THE CLOSING ANIMATION
      setIsClosing(false); // RESETS AFTER ANIMATION ENDS
      setTimeout(() => {
        setIsMenuOpen(false);
        setIsClosing(false); // RESETS AFTER ANIMATION ENDS
      }, 450); // TIMEOUT MATCHES THE SLIDE-UP ANIMATION DURATION
    } else {
      setIsMenuOpen(true);
    }
  };

  // FUNCTION TO SCROLL TO TOP OF THE PAGE
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setIsMenuOpen(false); // CLOSE MENU AFTER SCROLL
  };

  // FUNCTION TO SCROLL TO A SPECIFIC SECTION BY ID
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
      setIsMenuOpen(false); // CLOSE MENU AFTER SCROLL
    }
  };

  return (
    <>
      <Helmet>
        <title>Navigation Bar | 915Movement</title>
        <meta
          name="description"
          content="Navigate through 915Movement's website with our interactive navigation bar. Access sections like About Us, Services, Gallery, and more with ease."
        />
        <meta
          name="keywords"
          content="915Movement, navigation bar, menu, mobile party, El Paso, Las Cruces, about us, services, gallery, call now"
        />
        <meta name="author" content="915Movement" />
        <meta property="og:title" content="Navigation Bar | 915Movement" />
        <meta
          property="og:description"
          content="Explore 915Movement's website using our intuitive navigation bar. Access all sections seamlessly and contact us directly with our Call Now feature."
        />
        <meta property="og:image" content={logo} />
        <meta property="og:url" content="https://915mvmt.com/" />
        <meta property="og:type" content="website" />
      </Helmet>
      <nav className={styles.navbar}>
        <div className={styles.navbarContent}>
          {/* LOGO LINK - SCROLLS TO TOP ON CLICK */}
          <a
            href="#"
            className={styles.logoLink}
            onClick={(e) => {
              e.preventDefault(); // PREVENT DEFAULT ANCHOR BEHAVIOR
              scrollToTop(); // SCROLL TO TOP ON LOGO CLICK
            }}
            onMouseEnter={() => setIsHovered(true)} // TOGGLE LOGO ON HOVER
            onMouseLeave={() => setIsHovered(false)}
          >
            <img
              className={styles.logoIcon}
              alt="Logo"
              src={isHovered ? logo2 : logo} // CHANGE LOGO BASED ON HOVER STATE
            />
          </a>
          {/* HAMBURGER MENU BUTTON */}
          <button
            className={`${styles.hamburger} ${isMenuOpen ? styles.active : ""}`}
            onClick={toggleMenu}
          >
            <span className={styles.hamburgerLine}></span>
            <span className={styles.hamburgerLine}></span>
            <span className={styles.hamburgerLine}></span>
          </button>
          {/* NAVIGATION LINKS CONTAINER */}
          <div
            className={`${styles.navLinks} ${
              isMenuOpen ? (isClosing ? styles.closing : styles.active) : ""
            }`}
          >
            {/* NAVIGATION LINKS */}
            <div
              className={styles.linkItem}
              onClick={() => scrollToSection("aboutUsSection")}
            >
              About Us
            </div>
            <div
              className={styles.linkItem}
              onClick={() => scrollToSection("servicesSection")}
            >
              Services
            </div>
            <div
              className={styles.linkItem}
              onClick={() => scrollToSection("gallerySection")}
            >
              Gallery
            </div>
            {/* CALL NOW BUTTON INSIDE NAV LINKS */}
            <div className={styles.callNowContainer}>
              <a href="tel:+19152075261" className={styles.callNow}>
                Call Now!
              </a>
            </div>
          </div>
        </div>
        {/* FIXED CALL NOW BUTTON AT THE BOTTOM RIGHT */}
        <a href="tel:+19152075261" className={styles.fixedCallNow}>
          Call Now!
        </a>
      </nav>
    </>
  );
};

export default NavBar;
