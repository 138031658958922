import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import styles from "./Gallery.module.scss";

// Import all images directly
import image1 from "../../assets/pictures/915MOVEMENT_001.webp";
import image2 from "../../assets/pictures/915MOVEMENT_002.webp";
import image3 from "../../assets/pictures/915MOVEMENT_003.webp";
import image4 from "../../assets/pictures/915MOVEMENT_004.webp";
import image5 from "../../assets/pictures/915MOVEMENT_005.webp";
import image6 from "../../assets/pictures/915MOVEMENT_006.webp";
import image7 from "../../assets/pictures/915MOVEMENT_007.webp";
import image9 from "../../assets/pictures/915MOVEMENT_009.webp";
import image10 from "../../assets/pictures/915MOVEMENT_010.webp";
import image11 from "../../assets/pictures/915MOVEMENT_011.webp";
import image12 from "../../assets/pictures/915MOVEMENT_012.webp";
import image13 from "../../assets/pictures/915MOVEMENT_013.webp";
import image14 from "../../assets/pictures/915MOVEMENT_014.webp";
import image15 from "../../assets/pictures/915MOVEMENT_015.webp";
import image16 from "../../assets/pictures/915MOVEMENT_016.webp";
import image17 from "../../assets/pictures/915MOVEMENT_017.webp";
import image18 from "../../assets/pictures/915MOVEMENT_018.webp";

const Gallery = () => {
  const [activeImage, setActiveImage] = useState(null); // State to track the active image
  const [isClosing, setIsClosing] = useState(false); // State to track whether the modal is closing
  const [imagesLoaded, setImagesLoaded] = useState(false); // State to track if images are preloaded

  // Array of imported images
  const images = [
    image1, image2, image3, image4, image5, image6, image7,
    image9, image10, image11, image12, image13, image14, image15, image16,
    image17, image18
  ];

  // Delay the image preloading by a few seconds to avoid slowing down the page
  useEffect(() => {
    const preloadImages = () => {
      const imagePromises = images.map((src) => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.src = src;
          img.onload = resolve;
          img.onerror = reject;
        });
      });

      Promise.all(imagePromises)
        .then(() => setImagesLoaded(true)) // All images preloaded
        .catch((error) => console.error("Error preloading images", error));
    };

    const delayPreload = setTimeout(preloadImages, 3000); // Delay by 3 seconds

    // Clear the timeout if the component unmounts
    return () => clearTimeout(delayPreload);
  }, [images]);

  // Handle image click to open the modal
  const handleImageClick = (src) => {
    setIsClosing(false); // Ensure the closing state is reset
    setActiveImage(src); // Open the modal with the clicked image
  };

  // Handle closing the modal
  const handleClose = () => {
    setIsClosing(true); // Start the closing animation
    setTimeout(() => {
      setActiveImage(null); // After animation, hide modal
      setIsClosing(false); // Reset closing state
    }, 300); // Match the animation duration
  };

  return (
    <>
      <Helmet>
        <title>Gallery | 915Movement</title>
        <meta
          name="description"
          content="Explore our gallery showcasing vibrant and engaging images of 915Movement's mobile party experiences. See the excitement we bring to every event."
        />
        <meta
          name="keywords"
          content="915Movement gallery, mobile party, event photos, party images, 915Movement events, El Paso, Las Cruces, party bus photos"
        />
        <meta name="author" content="915Movement" />
        <meta property="og:title" content="Gallery | 915Movement" />
        <meta
          property="og:description"
          content="Check out the gallery of 915Movement, featuring exciting and lively photos of our mobile party services in El Paso and Las Cruces."
        />
        <meta property="og:image" content={images[0]} />
        <meta property="og:url" content="https://915mvmt.com/" />
        <meta property="og:type" content="website" />
      </Helmet>

      {/* Show the loading state until all images are preloaded */}
      <div id="gallerySection" className={styles.gallerySection}>
        <h2 className={styles.galleryTitle}>Gallery</h2>
        {imagesLoaded ? (
          <div className={styles.bentoGrid}>
            {images.map((src, index) => (
              <button
                key={index}
                className={styles.bentoItem}
                onClick={() => handleImageClick(src)} // Set active image on click
                aria-label={`Open enlarged image ${index + 1}`}
              >
                <img
                  src={src}
                  alt={`Gallery Image ${index + 1}`}
                  className={styles.image}
                  loading="lazy" // Lazy loading attribute for images
                />
              </button>
            ))}
          </div>
        ) : (
          <p>Loading gallery...</p> // Simple fallback while preloading
        )}

        {/* Modal for showing enlarged image */}
        {activeImage && (
          <div className={styles.modalOverlay} onClick={handleClose}>
            <div
              className={`${styles.modalContent} ${
                isClosing ? styles.closing : styles.active
              }`}
              onClick={(e) => e.stopPropagation()}
            >
              <span className={styles.closeButton} onClick={handleClose}>
                &times;
              </span>
              <img
                src={activeImage}
                alt="Enlarged view"
                className={styles.enlargedImage}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Gallery;
