import React from 'react';
import NavBar from './components/navbar/navbar';
import Hero from './components/hero/hero';
import AboutUs from './components/about/about';
import Services from './components/Services/Services';
import Footer from './components/Footer/Footer';
import Gallery from './components/Gallery/Gallery';
import './App.scss';

const App = () => {
  return (
    <div className="App">
      <NavBar />
      <main className="App-content">
        <Hero />
        <AboutUs />
        <Services />
        <Gallery />
        <Footer />
      </main>
    </div>
  );
};

export default App;
