import React from 'react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing meta tags
import styles from './about.module.scss';
import image from '../../AboutUs.webp';

const AboutUs = () => {
  return (
    <>
      <Helmet>
        <title>About Us | 915Movement</title>
        <meta
          name="description"
          content="Discover 915Movement, the ultimate mobile party experience in El Paso and Las Cruces. Explore our top-of-the-line fleet with premium sound, LED lighting, and spacious interiors for an unforgettable ride."
        />
        <meta
          name="keywords"
          content="915Movement, mobile party, El Paso, Las Cruces, party bus, premium sound, LED lighting, VIP ride, mobile events, El Paso party services"
        />
        <meta name="author" content="915Movement" />
        <meta property="og:title" content="About Us | 915Movement" />
        <meta
          property="og:description"
          content="Join 915Movement and experience the ultimate mobile party. Our fleet is equipped with premium sound systems, vibrant LED lighting, and spacious interiors for your perfect event."
        />
        <meta property="og:image" content={image} />
        <meta property="og:url" content="https://915mvmt.com/" />
        <meta property="og:type" content="website" />
      </Helmet>
      <section id="aboutUsSection" className={styles.aboutUs}>
        <div className={styles.content}>
          <h2 className={styles.title}>About Us</h2>
          <p className={styles.text}>
            At 915Movement, we redefine what it means to party on the go! Based in El Paso, Texas, and proudly serving El Paso County and Las Cruces, we bring the ultimate mobile party experience right to your doorstep.
          </p>
          <p className={styles.text}>
            Our top-of-the-line fleet features premium sound systems, vibrant LED lighting, and spacious interiors that keep the energy alive from start to finish. Safety is our priority, with licensed professionals dedicated to providing a smooth and secure ride.
          </p>
        </div>
        <div className={styles.imageContainer}>
          <img src={image} alt="About Us" className={styles.image} />
        </div>
      </section>
    </>
  );
};

export default AboutUs;
