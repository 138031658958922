import React from "react";
import { Helmet } from "react-helmet"; // Import Helmet for managing meta tags
import styles from "./hero.module.scss"; // Ensure the CSS file path is correct

const Hero = () => {
  // FUNCTION TO SCROLL SMOOTHLY TO THE NEXT SECTION
  const scrollToNextSection = () => {
    const nextSection = document.getElementById("aboutUsSection");
    if (nextSection) {
      nextSection.scrollIntoView({ behavior: "smooth" }); // SCROLLS TO 'About Us' SECTION
    }
  };

  return (
    <>
      <Helmet>
        <title>Hero Section | 915Movement</title>
        <meta
          name="description"
          content="Welcome to 915Movement's Hero section, showcasing our vibrant promotional video that highlights the ultimate mobile party experience in El Paso and Las Cruces."
        />
        <meta
          name="keywords"
          content="915Movement, hero section, mobile party, promo video, El Paso, Las Cruces, party bus, VIP experience, mobile events"
        />
        <meta name="author" content="915Movement" />
        <meta property="og:title" content="Hero Section | 915Movement" />
        <meta
          property="og:description"
          content="Watch our promotional video that captures the excitement and energy of 915Movement's mobile party services. Join us for the best party experience on wheels."
        />
        <meta
          property="og:image"
          content="https://www.915mvmt.com" // Replace with the actual thumbnail URL of your video
        />
        <meta property="og:url" content="https://915mvmt.com/" />
        <meta property="og:type" content="video.other" />
      </Helmet>

      <div className={styles.heroSection}>
        {/* VIDEO CONTAINER: USE MEDIA QUERIES TO LOAD LANDSCAPE OR PORTRAIT */}
        <div className={styles.videoContainer}>
          {/* LANDSCAPE VIDEO FOR DESKTOP SCREENS */}
          <iframe
            className={`${styles.backgroundVideo} ${styles.landscapeVideo}`}
            src="https://www.youtube.com/embed/83_bVwFFObA?si=JNZFHmwYtJz0Wqvt&autoplay=1&controls=0&mute=1&loop=1&playlist=83_bVwFFObA"
            title="915MVMT Promo Landscape"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>

          {/* PORTRAIT VIDEO FOR SMALLER SCREENS */}
          <iframe
            className={`${styles.backgroundVideo} ${styles.portraitVideo}`}
            src="https://www.youtube.com/embed/OxQhfVkULwc?autoplay=1&controls=0&mute=1&loop=1&playlist=OxQhfVkULwc"
            title="915MVMT Promo Portrait"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        </div>

        {/* ARROW CONTAINER FOR SCROLL FUNCTIONALITY */}
        <div className={styles.arrowContainer} onClick={scrollToNextSection}>
          <div className={styles.downArrow}></div>
        </div>
      </div>
    </>
  );
};

export default Hero;
